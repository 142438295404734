import { YOUTUBE_API_KEY } from '@pretto/website/config'
import { type VideoDataQueryType } from '@pretto/website/src/components/YoutubeVideo/types/videoDataQuery.type'
import { type VideoSchemaType } from '@pretto/website/src/components/YoutubeVideo/types/videoSchema.type'

import { useEffect, useState } from 'react'

export const useVideoMicroData = (source: string) => {
  const [microdataSchema, setMicrodataSchema] = useState<VideoSchemaType | undefined>()

  const videoId = source.match(/embed\/([a-zA-Z0-9_-]+)/)?.[1]
  const queryParts = ['snippet', 'contentDetails', 'statistics']
  const urlQuery = `https://www.googleapis.com/youtube/v3/videos?part=${queryParts.join()}&id=${videoId}&key=${YOUTUBE_API_KEY}`

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(urlQuery, {
          method: 'GET',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
          },
        })

        if (response.ok) {
          const data = (await response.json()) as VideoDataQueryType
          const { snippet, contentDetails, statistics } = data.items[0]

          setMicrodataSchema({
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: snippet.title,
            description: snippet.description,
            thumbnailUrl: snippet.thumbnails.default.url,
            uploadDate: snippet.publishedAt,
            contentUrl: `https://www.youtube.com/watch?v=${videoId}`,
            embedUrl: source,
            duration: contentDetails.duration,
            interactionStatistic: {
              '@type': 'InteractionCounter',
              interactionType: { '@type': 'WatchAction' },
              userInteractionCount: statistics.viewCount,
            },
          })
        }
      } catch (error) {
        throw new Error(`There is an error in Youtube API V3 call : ${error}`)
      }
    })()
  }, [source])

  return microdataSchema
}
