import { VideoSchemaType } from '@pretto/website/src/components/YoutubeVideo/types/videoSchema.type'

import Helmet from 'react-helmet'

import * as S from './YoutubeVideo.styles'

export type YoutubeVideoProps = {
  source: string
  microdataSchema?: VideoSchemaType
  width: string
}

export const YoutubeVideo: React.FC<YoutubeVideoProps> = ({ microdataSchema, source, width }) => (
  <S.YoutubeVideo $width={width}>
    <S.Container>
      <S.Iframe
        allow="accelerometer;encrypted-media;gyroscope;picture-in-picture;autoplay;"
        allowFullScreen
        frameBorder="0"
        src={source}
      />
    </S.Container>

    {microdataSchema && (
      <Helmet>
        <script id="microDataVideo" type="application/ld+json">
          {JSON.stringify(microdataSchema)}
        </script>
      </Helmet>
    )}
  </S.YoutubeVideo>
)
