import * as S from '@pretto/bricks/website/utility/ContentBlock/styles'

import { YoutubeVideo, YoutubeVideoProps } from '@pretto/website/src/components/YoutubeVideo/YoutubeVideo'
import { useVideoMicroData } from '@pretto/website/src/components/YoutubeVideo/lib/useVideoMicroData'

export const ContentYoutubeVideo: React.FC<YoutubeVideoProps> = ({ source, width, ...props }) => {
  const microdataSchema = useVideoMicroData(source)

  return (
    <S.BlockLarge {...props}>
      <YoutubeVideo source={source} microdataSchema={microdataSchema} width={width} />
    </S.BlockLarge>
  )
}
